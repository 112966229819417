import FinanceApi from '@/services/FinanceApi';

const numberOfStudents = async (config) => {
    return FinanceApi.get('/reports/number-of-students', config);
}

const collection = async (config) => {
    return FinanceApi.get('/reports/collection', config);
}

const studentCollection = async (config) => {
    return FinanceApi.get('/reports/student-collection', config);
}

const cashCollection = async (config) => {
    return FinanceApi.get('/reports/cash-collection', config);
}

const pilotage = async (config={}) => {
    return FinanceApi.get('/payment/plans/pilotage/report', config);
}

const balance = async (config) => {
    return FinanceApi.get('/reports/balance', config);
}

const educationCredits = async (config) => {
    return FinanceApi.get('/reports/education-credits', config);
}

const earlyPayment = async (config) => {
    return FinanceApi.get('/reports/early-payment', config);
}

const graduateFullScholarshipRatio = async (config) => {
    return FinanceApi.get('/reports/graduate-full-scholarship-ratio', config);
}

const agingSummaryReport = async (config) => {

    return FinanceApi.get('/payment/plans/aging-summary-report',config);

}
export default {
    numberOfStudents,
    collection,
    cashCollection,
    pilotage,
    studentCollection,
    balance,
    educationCredits,
    earlyPayment,
    graduateFullScholarshipRatio,
    agingSummaryReport
}
